<template>
<div class="booking-restaurant">
    <div style="margin-top: 3px; height: 310px">
        <LoadingDataError v-if="loadingError.isError" :ErrorCode="loadingError.errorCode" />

        <DataTable
            :value="restaurantBooking"
            responsiveLayout="scroll"
            scrollHeight="280px"
            v-model:selection="selectedRestaurantBooking" 
            selectionMode="single"
            dataKey="id"
            v-if="!loadingError.isError"
        >
            <!-- <Column field="id" header="ID" :style="{'min-width':'30px'}"></Column> -->
            <Column field="restaurant_name" header="Restaurant" :style="{'min-width':'190px'}"></Column>
            <Column field="province" header="City" :style="{'min-width':'100px'}"></Column>
            <Column field="meal" header="Meal" :style="{'min-width':'40px'}"></Column>
            <Column field="pax" header="Pax" :style="{'min-width':'40px'}"></Column>
            <Column field="child" header="Child" :style="{'min-width':'40px'}"></Column>
            <Column field="menu" header="Menu" :style="{'min-width':'100px'}"></Column>
            <Column field="reserved_date" header="Reserved Date" :style="{'min-width':'115px'}">
                <template #body="{data}">
                    {{data.reserved_date != null ? dayjs(data.reserved_date).format('DD-MMM-YYYY') : ''}}
                </template>
            </Column>

            <Column :exportable="false" :style="{'min-width':'75px', 'width':'75px', 'padding':'0.4rem 0rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button v-if="can('update', 'booking')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="onEditRestaurantBooking(slotProps.data)" />
                    <Button v-if="can('delete', 'booking')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteRestaurantBooking(slotProps.data)" />
                </template>
            </Column>
            <!-- <template #footer style="margin-top: 10px" v-if="selectedHotelBooking && selectedHotelBooking !== null">
                From: {{ selectedHotelBooking.from_date }}; &emsp; To: {{ selectedHotelBooking.to_date }};
            </template> -->
        </DataTable>
    </div>

    <hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
    <div style="display: flex; align-self: flex-end; margin-bottom: 10px">
        <Button v-if="can('create', 'booking')" label="Add Restaurant Booking" class="p-button p-button-sm" @click="openBookingRestForm()" :disabled="bookingID == 0 || loadingError.isError" />
        <Dialog v-model:visible="isBookingRestFormShow" header="Restaurant Booking" :style="{width: '53rem', margin: '0px 5px'}" :modal="true">
            <BookingRestaurantForm ref="BookingRestaurantFormRef" :BookingID="bookingID" @RefreshRestaurantBooking="getRestaurantBooking" />
        </Dialog>

        <Button icon="pi pi-print" label="Voucher" class="p-button-secondary p-button-sm p-ml-2" @click="printRestaurantVoucher()" :disabled="bookingID == 0 || loadingError.isError || !selectedRestaurantBooking" />
    </div>
</div>
</template>

<script>
import { ref, reactive, nextTick } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import BookingService from "../service/BookingService"
import LoadingDataError from '../components/LoadingDataError.vue'
import BookingRestaurantForm from '../components/BookingRestaurantForm.vue'
import dayjs from 'dayjs';

export default {
    setup() {
        const confirm = useConfirm();
        const toast = useToast();
        const { can } = useAbility()
        let restaurantBooking = ref([])
        let selectedRestaurantBooking = ref()
        let loadingError = reactive({isError: false, errorCode: ''})
        const isBookingRestFormShow = ref(false)
        let bookingID = ref(0)
        const BookingRestaurantFormRef = ref()

        const bookingService = ref(new BookingService())

        //=============Method=============
        const getRestaurantBooking = (Booking_ID) => {
            if(Booking_ID != 0) {
                //SEND BOOKING_ID TO RESTAURANT BOOKING FORM'S PROPS
                bookingID.value = Booking_ID

                restaurantBooking.value.length = 0;
                selectedRestaurantBooking.value = null

                bookingService.value.getBookingRestaurant(Booking_ID).then(data => {
                    if(!data.errorResponse) {
                        restaurantBooking.value = data

                        loadingError.isError = false
                    } else {
                        loadingError.isError = true
                        loadingError.errorCode = data.status
                    }
                })
            }
        }

        const openBookingRestForm = () => {
            isBookingRestFormShow.value = true
        }

        const onEditRestaurantBooking = (data) => {
            selectedRestaurantBooking.value = data
            
            isBookingRestFormShow.value = true
            //WAIT COMPONENT RENDERED THEN SEND SELECTED DATA TO FORM
            nextTick(() => {
                BookingRestaurantFormRef.value.editRestaurantBooking(selectedRestaurantBooking.value)
            })
        }

        const confirmDeleteRestaurantBooking = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.restaurant_name +'" booking?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const bookingRestID = data.id
                    bookingService.value.deleteBookingRestaurant(bookingRestID).then((data) => {
                        if(!data.errorResponse){
                            restaurantBooking.value = restaurantBooking.value.filter(val => val.id !== bookingRestID);
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 4500});
                        }
                    })
                }
            });
        }

        const printRestaurantVoucher = () => {
            window.open('/booking/print-restaurantvoucher/?bookingrestaurantid='+selectedRestaurantBooking.value.id, 'popUpWindow','height=880,width=720,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        }

        return {
            can,
            loadingError,
            restaurantBooking,
            selectedRestaurantBooking,
            isBookingRestFormShow,
            getRestaurantBooking,
            bookingID,
            
            dayjs,
            openBookingRestForm,
            BookingRestaurantFormRef,
            onEditRestaurantBooking,
            confirmDeleteRestaurantBooking,
            printRestaurantVoucher,
        }
    },
    components:{
        LoadingDataError,
        BookingRestaurantForm,
    }
}
</script>

<style lang="scss" scoped>

</style>