<template>
<div class="p-fluid" style="margin-top: 10px">
    <div class="p-grid">
        <div class="p-col-6">
            <label for="restaurant_name">Restaurant name:</label>
            <AutoComplete v-model="selectedRestaurant" :suggestions="filteredRestaurants" @complete="searchRestaurant($event)" @item-select="onRestaurantNameSelect($event)" field="restaurant_name" :class="{'p-invalid':v_bookingrest$.restaurant_id.$error}" />
        </div>
        <div class="p-col-6">
            <label for="meal">Meal:</label>
            <Dropdown v-model="selectedMeal" :options="restaurant_meal" @change="onMealChange()" optionLabel="meal" :class="{'p-invalid':v_bookingrest$.meal.$error} "></Dropdown>
        </div>
    </div>
    <div class="p-grid" style="align-items: flex-end;">
        <div class="p-col">
            <label for="adult">Pax:</label>
            <InputNumber id="pax" v-model="restBookingForm.pax" :class="{'p-invalid':v_bookingrest$.pax.$error}" />
        </div>
        <div class="p-col">
            <label for="child">Child:</label>
            <InputNumber id="child" v-model="restBookingForm.child" />
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-6">
            <label for="menu">Menu:</label>
            <Dropdown v-model="selectedMenu" :options="restaurantMenu" @change="onMenuChange()" optionLabel="menu_name" :class="{'p-invalid':v_bookingrest$.menu_id.$error} "></Dropdown>
        </div>
        <div class="p-col-6">
            <label for="reserved_date">Date:</label>
            <Calendar id="reserved_date" v-model="restBookingForm.reserved_date" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookingrest$.reserved_date.$error || !isBookingDateValid}" />
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col" style="padding-top: 15px; align-self: flex-end">
            <Button v-if="!isEditRestaurantBooking" label="Save Restaurant Booking" @click="saveRestaurantBooking()" class="p-button-success p-button-sm" style="width: 165px;"></Button>
            <Button v-if="isEditRestaurantBooking" label="Update Restaurant Booking" @click="updateRestaurantBooking()" class="p-button-success p-button-sm p-mr-1" style="width: 180px" />
        </div>
    </div>
</div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import RestaurantService from '../service/RestaurantService'
import BookingService from '../service/BookingService'

export default {
    props: {
        BookingID: Number
    },
    emits: ['RefreshRestaurantBooking'],
    setup(props, { emit }) {
        const toast = useToast()
        const selectedRestaurant = ref()
        const filteredRestaurants = ref()
        let restaurantID = ref(0)
        const restaurant_meal = [{meal: 'Breakfast'}, {meal: 'Lunch'}, {meal: 'Dinner'}]
        let selectedMeal = ref()

        let restaurantMenu = ref([])
        let selectedMenu = ref()

        let restBookingForm = reactive({})
        let isEditRestaurantBooking = ref(false)
        let isBookingDateValid = ref(true)

        const restaurantService = ref(new RestaurantService())
        const bookingService = ref(new BookingService())

        //=============Method=============
        const searchRestaurant = (event) => {
            setTimeout(() => {
                if (event.query.trim().length) {
                    restaurantService.value.searchRestaurant(event.query.toLowerCase()).then(data => {
                        if(!data.errorResponse) {
                            if(data.length > 0) {
                                filteredRestaurants.value = data
                            } else {
                                filteredRestaurants.value = [{restaurant_name: 'No result'}]
                                restaurantMenu.value.length = 0
                            }
                        }
                    })
                }
            }, 250);
        }

        const onRestaurantNameSelect = (event) => {
            if(event.value.id) {
                restaurantID.value = event.value.id
                restBookingForm.restaurant_id = restaurantID.value
                getRestaurantMenu(restaurantID.value)
                restBookingForm.menu_id = ''
            } else {
                selectedRestaurant.value = null
                restBookingForm.restaurant_id = ''
                restBookingForm.menu_id = ''
            }
        }

        const getRestaurantMenu = (restaurant_id) => {
            if (restaurant_id != 0) {
                restaurantService.value.getMenu(restaurant_id).then(data => {
                    if(!data.errorResponse) {
                        restaurantMenu.value = data
                    }

                    if(restBookingForm.menu_id != '') {
                        selectedMenu.value = restaurantMenu.value.find(item => item.id === restBookingForm.menu_id)
                    }
                })
            }
        }

        const onMealChange = () => {
            restBookingForm.meal = selectedMeal.value.meal
        }

        const onMenuChange = () => {
            restBookingForm.menu_id = selectedMenu.value.id
        }

        const initRestBookingForm = () => {
            const restaurant_booking_form = {
                id: '',
                meal: '',
                pax: null,
                child: null,
                reserved_date: '',
                menu_id: '',
                restaurant_id: '',
                booking_id: '',
            }

            selectedMenu.value = null
            selectedRestaurant.value = null
            selectedMeal.value = null

            Object.assign(restBookingForm, restaurant_booking_form)
        }

        const saveRestaurantBooking = () => {
            if(validateForm() && props.BookingID != 0) {
                restBookingForm.booking_id = props.BookingID
                bookingService.value.addBookingRestaurant(restBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Save Error', detail: data.message, life: 4500}); 
                        } else {
                            initRestBookingForm()
                            filteredRestaurants.value.length = 0
                            v_bookingrest$.value.$reset();
                            isBookingDateValid.value = true
                            emit('RefreshRestaurantBooking', props.BookingID)
                            toast.add({severity:'success', summary: 'Success', detail:'Restaurant booking has been created.', life: 3000}); 
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        const editRestaurantBooking = (emitData) => {
            isEditRestaurantBooking.value = true
            initRestBookingForm()
            
            Object.keys(restBookingForm).forEach((key)=>{
                restBookingForm[key] = emitData[key]
            })
            selectedMeal.value = restaurant_meal.find(item => item.meal === restBookingForm.meal)
            restBookingForm.reserved_date = new Date(restBookingForm.reserved_date)
            
            selectedRestaurant.value = emitData.restaurant_name
            getRestaurantMenu(emitData.restaurant_id)
        }

        const updateRestaurantBooking = () => {
            if(validateForm() && props.BookingID != 0) {               
                bookingService.value.updateBookingRestaurant(restBookingForm.id, restBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Update Error', detail: data.message, life: 4500}); 
                        } else {
                            v_bookingrest$.value.$reset();
                            emit('RefreshRestaurantBooking', props.BookingID)
                            isBookingDateValid.value = true
                            toast.add({severity:'success', summary: 'Success', detail:'Restaurant booking has been updated.', life: 3000});        
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        //=================Form Validation=================
        const bookingRestaurantRules = computed(() => {
            return {
                restaurant_id: { required },
                meal: { required },
                pax: { required },
                menu_id: { required },
                reserved_date: { required },
            }
        })
        const v_bookingrest$ = useVuelidate(bookingRestaurantRules, restBookingForm)
        const validateForm = () => {
            v_bookingrest$.value.$validate();
            if(!v_bookingrest$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            selectedRestaurant,
            filteredRestaurants,
            searchRestaurant,
            onRestaurantNameSelect,
            saveRestaurantBooking,

            restaurant_meal,
            selectedMeal,
            onMealChange,
            restaurantMenu,
            selectedMenu,
            onMenuChange,

            v_bookingrest$,
            isBookingDateValid,
            // bookingID,
            restBookingForm,
            editRestaurantBooking,
            isEditRestaurantBooking,
            updateRestaurantBooking,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>